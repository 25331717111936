import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import MUIDataTable from 'mui-datatables';

import { MdEdit, MdDelete } from 'react-icons/md';
import { toast } from 'react-toastify';
import CustomToolbar from './CustomToolBar';
import api from '../../services/api';

import SelectCompany from '../../components/SelectCompany';
import SelectCustomer from '../../components/SelectCustomer';

import { Container, Table, DeleteButton } from './styles';

export default function UserReports() {
  const [company, setCompany] = useState();
  const [data, setData] = useState();
  const profile = useSelector(state => state.user.profile);

  async function loadData() {
    const response = await api.get('users', {
      params: {
        company_id: company,
      },
    });

    setData(response.data);
  }

  function handleLoad() {
    loadData();
  }

  async function loadProfileData() {
    const response = await api.get('users', {
      params: {
        company_id: profile.company_id,
      },
    });

    setData(response.data);
  }

  function handleProfile() {
    loadProfileData();
  }

  async function handleDelete(id) {
    await api.delete(`/users/${id}`);
    toast.info('Usuário Excluído');
    handleLoad();
  }

  const columns = [
    {
      name: 'id',
      label: 'Cod',
      options: {
        filter: false,
      },
    },
    {
      name: 'name',
      label: 'Colaborador',
      options: {
        filter: false,
      },
    },
    {
      name: 'cpf',
      label: 'Matrícula',
      options: {
        filter: false,
      },
    },
    {
      name: 'email',
      label: 'E-mail',
      options: {
        filter: false,
      },
    },
    {
      name: 'office',
      label: 'Função',
      options: {
        filter: true,
      },
    },
    {
      name: 'departments.name',
      label: 'Setor',
      options: {
        filter: true,
      },
    },
    // {
    //   name: 'office',
    //   label: 'Função',
    //   options: {
    //     filter: true,
    //     filterType: 'checkbox',
    //     customBodyRender: value => {
    //       return value ? 'Porter' : 'Kiper';
    //     },
    //   },
    // },

    {
      name: 'id',
      label: 'Editar',
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRender: value => {
          return (
            <Link to={`/users/${value}`}>
              <MdEdit size={25} color="#666" />
            </Link>
          );
        },
      },
    },

    {
      name: 'id',
      label: 'Excluir',
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRender: value => {
          return (
            <DeleteButton
              type="button"
              onClick={() => {
                handleDelete(value);
              }}
            >
              <MdDelete size={25} color="#666" />
            </DeleteButton>
          );
        },
      },
    },
  ];

  const options = {
    filter: true,
    filterType: 'dropdown',
    responsive: 'scrollFullHeight',

    selectableRows: 'none',
    customToolbar: () => {
      return <CustomToolbar />;
    },
  };

  return (
    <Container>
      <form>
        {profile.company_id && profile.company_id === 1 ? (
          <SelectCompany changeCompany={e => setCompany(e.value)} />
        ) : (
          <SelectCustomer changeCompany={e => setCompany(e.value)} />
        )}

        {/* <DatePicker
          selected={date1}
          onChange={date => setDate1(date)}
          locale={ptBR}
          dateFormat="dd/MM/yyyy"
        />

        <DatePicker
          selected={date2}
          onChange={date => setDate2(date)}
          locale={ptBR}
          dateFormat="dd/MM/yyyy"
        /> */}
        {profile.company_id && profile.company_id === 1 ? (
          <button type="button" onClick={handleLoad}>
            GERAR
          </button>
        ) : (
          <button type="button" onClick={handleProfile}>
            GERAR
          </button>
        )}
      </form>

      <Table>
        <MUIDataTable
          title="Colaboradores"
          data={data}
          columns={columns}
          options={options}
        />
      </Table>
    </Container>
  );
}
