import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: top;
  width: 90%;
  height: 90%;
  background: #333;
  flex-direction: column;
  margin: 0 auto;
`;

export const CompanyCard = styled.ul`
  background: #eee;
  display: flex;
  width: 60%;
  height: 15%;
  align-items: center;
  margin: 20px;
  justify-content: space-around;
  font-size: 20px;
  border-radius: 4px;

  div {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  strong {
    margin-left: 5px;
  }
`;

export const UserCard = styled.ul`
  background: #eee;
  display: flex;
  width: 60%;
  height: 15%;
  align-items: center;
  justify-content: space-around;
  font-size: 20px;
  border-radius: 4px;
  margin: 0 20px 20px 0;

  div {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  strong {
    margin-left: 5px;
  }
`;

export const TerminalCard = styled.ul`
  background: #eee;
  display: flex;
  width: 60%;
  height: 15%;
  align-items: center;
  justify-content: space-around;
  font-size: 20px;
  border-radius: 4px;
  margin: 0 20px 20px 0;

  div {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  strong {
    margin-left: 5px;
  }
`;
