/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Autocomplete } from '@material-ui/lab';
import { TextField, CircularProgress } from '@material-ui/core';
import api from '../../services/api';

export default function DepartmentComboBox(props) {
  const [open, setOpen] = useState(false);
  const [options, setOptions] = React.useState([]);
  // const profile = useSelector(state => state.user.profile);
  const loading = open && options.length === 0;
  const { id, name, label, changeDepartment, comboCompany } = props;

  useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    (async () => {
      const response = await api.get('departments', {
        params: {
          company_id: comboCompany,
        },
      });
      const departmentOptions = await response.data;

      if (active && comboCompany) {
        setOptions(
          departmentOptions.map(c => ({
            id: c.id,
            name: c.name,
          }))
        );
      }
    })();

    return () => {
      active = false;
    };
  }, [comboCompany, loading]);

  React.useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  return (
    <Autocomplete
      id={id}
      name={name}
      style={{ width: 350 }}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      getOptionLabel={option => option.name}
      getOptionSelected={(option, value) => option.name === value.name}
      options={options}
      loading={loading}
      onChange={changeDepartment}
      disableClearable
      renderInput={params => (
        <TextField
          {...params}
          label={label}
          variant="outlined"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
}

DepartmentComboBox.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  changeDepartment: PropTypes.func.isRequired,
  comboCompany: PropTypes.number,
};

DepartmentComboBox.defaultProps = {
  comboCompany: PropTypes.number,
};
