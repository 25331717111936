import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import confirmPageLogo from '../../assets/logo_confirm_page.png';
import checkLogo from '../../assets/check.jpg';
import {
  Container,
  InformContainer,
  Logo,
  Title,
  CheckContainer,
  CheckLogo,
} from './styles';

export default function ValidationGenerated() {
  const history = useHistory();

  useEffect(() => {
    const interval = setInterval(() => {
      history.push('/terminal');
    }, 2000);
    return () => clearInterval(interval);
  }, [history]);

  return (
    <>
      <Container>
        <InformContainer>
          <Logo>
            <img src={confirmPageLogo} alt="RecipeImg" />
          </Logo>
          <CheckContainer>
            <CheckLogo>
              <img src={checkLogo} alt="CheckLogo" />
            </CheckLogo>
            <Title>Refeição Confirmada!</Title>
          </CheckContainer>
        </InformContainer>
      </Container>
    </>
  );
}
