import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`;
export const Logo = styled.div`
  display: flex;
  margin-left: 30px;
  height: 140px;
`;
export const Title = styled.text`
  font-family: 'Montserrat';
  font-size: 17px;
  margin: 10px 0 30px 0;
`;

export const UserContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const UserTitle = styled.strong`
  font-family: 'Montserrat';
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 10px;
  margin-right: 10px;
`;

export const UserName = styled.text`
  font-family: 'Poppins';
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 8px;
`;
export const OfficeContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const OfficeTitle = styled.strong`
  font-family: 'Montserrat';
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 10px;
  margin-right: 10px;
`;
export const UserOffice = styled.strong`
  font-family: 'Poppins';
  font-size: 15px;
  font-weight: bold;
  margin-bottom: 10px;
`;
export const ValidationDate = styled.text`
  font-family: 'Montserrat';
  font-size: 15px;
  font-weight: bold;
  margin-bottom: 8px;
  margin-left: 55px;
  margin-top: 20px;
`;

export const DepartmentContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const DepartmentTitle = styled.strong`
  font-family: 'Montserrat';
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 10px;
  margin-right: 10px;
`;
export const UserDepartment = styled.strong`
  font-family: 'Poppins';
  font-size: 15px;
  font-weight: bold;
  margin-bottom: 10px;
`;

export const UserCompany = styled.strong`
  font-family: 'Montserrat';
  font-size: 20px;
  font-weight: bold;
  margin-top: 20px;
  color: #21351e;
`;
