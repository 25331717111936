import { combineReducers } from 'redux';

import auth from './auth/reducer';
import user from './user/reducer';
import company from './company/reducer';

const reducers = combineReducers({
  auth,
  user,
  company,
});

export default reducers;
