import styled from 'styled-components';
import { Link } from 'react-router-dom';

import homeImage from '../../assets/middle_background_sabor.jpg';

export const Container = styled.div`
  height: 94vh;
  display: flex;
  align-items: stretch;
  background: #fdfee8;
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  max-width: 600px;
  border-right: 3px solid;
  border-color: #f5a23d;
`;

export const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  max-width: 600px;
`;

export const HomeTitle = styled.h1`
  color: #f5a23d;
  margin-bottom: 20px;
  margin-top: 20px;
  margin-left: 30px;
  font-size: 45px;
  background: #2c2e2c;
  height: 70px;
  width: 90%;
  border-bottom: 1px solid #f5a23d;
  padding-left: 5px;
  border-radius: 5px;

  -webkit-box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.75);
`;

export const Background = styled.div`
  flex: 1;
  background: url(${homeImage}) no-repeat center;
  background-size: cover;
`;

export const KeyboardCard = styled(Link)`
  width: 160px;
  height: 40px;
  padding-left: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background: #2c2e2c;
  border-color: #2c2a2c;
  border-radius: 4px;
  text-align: center;
  margin-left: 15px;
  margin-bottom: 15px;
  -webkit-box-shadow: 8px 5px 10px 1px rgba(0, 0, 0, 0.62);
  -moz-box-shadow: 8px 5px 10px 1px rgba(0, 0, 0, 0.62);
  box-shadow: 8px 5px 10px 1px rgba(0, 0, 0, 0.62);
`;

export const KeyboardCardTitle = styled.h1`
  color: #f5a23d;
  font-size: 16px;
  font-family: 'Montserrat';
  font-weight: 500;
  padding-top: 2px;
  margin-left: 6px;
`;

export const TerminalV1Card = styled(Link)`
  padding-left: 10px;
  display: flex;
  width: 160px;
  height: 40px;
  align-items: center;
  justify-content: flex-start;
  /* background: #ffdb12; */
  background: #2c2e2c;
  border-color: #2c2a2c;
  border-radius: 4px;
  margin-left: 15px;
  margin-bottom: 25px;
  -webkit-box-shadow: 8px 5px 10px 1px rgba(0, 0, 0, 0.62);
  -moz-box-shadow: 8px 5px 10px 1px rgba(0, 0, 0, 0.62);
  box-shadow: 8px 5px 10px 1px rgba(0, 0, 0, 0.62);
`;

export const TerminalV1CardTitle = styled.h1`
  color: #f5a23d;
  font-size: 16px;
  font-family: 'Montserrat';
  font-weight: 500;
  padding-top: 3px;
  margin-left: 6px;
`;
export const LogoutCard = styled.button`
  width: 160px;
  height: 40px;
  text-align: initial;
  background: #2c2e2c;
  border: 1px solid;
  border-color: #2c2a2c;
  border-radius: 4px;
  margin-left: 15px;
  margin-bottom: 35px;
  -webkit-box-shadow: 8px 5px 10px 1px rgba(0, 0, 0, 0.62);
  -moz-box-shadow: 8px 5px 10px 1px rgba(0, 0, 0, 0.62);
  box-shadow: 8px 5px 10px 1px rgba(0, 0, 0, 0.62);
  padding-bottom: 2px;
  padding-left: 10px;
`;

export const LogoutCardTitle = styled.strong`
  color: #f5a23d;
  font-size: 16px;
  font-family: 'Montserrat';
  font-weight: 500;
  margin-left: 10px;
`;
export const ItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  margin: 10px;
  align-items: flex-start;
  justify-content: space-between;
  padding: 20px;
  padding-bottom: 40px;
`;
