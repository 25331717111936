import styled from 'styled-components';

import { Select } from '@rocketseat/unform';
import { lighten } from 'polished';

export const Container = styled.div`
  display: flex;
  background: #fff;
  padding: 10px 30px;
  flex-direction: column;

  form {
    opacity: ${props => (props.loading ? 0.2 : 1)};
    display: flex;
    flex-direction: row;

    input {
      border-radius: 4px;
      height: 40px;
      width: 160px;
      padding: 0 15px;
      margin-right: 15px;
      margin-bottom: 25px;
      text-align: center;

      &::placeholder {
        color: rgba(0, 0, 0, 0.7);
      }
    }

    > button {
      height: 38px;
      width: 120px;
      background: ${props => (props.perfil ? '#f5a118' : '#f5a118')};
      font-weight: bold;
      color: #fff;
      border: 0;
      border-radius: 4px;
      font-size: 16px;
      transition: background 0.3s;
      display: ${props => (props.loading ? 'none' : 'block')};

      &:hover {
        background: ${props =>
          props.perfil ? lighten(0.09, '#f5a118') : lighten(0.09, '#f5a118')};
      }
    }
  }
`;

export const Selector = styled(Select)`
  width: 150px;
  max-width: auto;
  margin: 5px;
  height: 40px;
  margin-bottom: 10px;
  align-self: center;
  border-radius: 5px;
  font-family: 14px sans-serif;
  background: #fff;
  color: #666;
  padding: 0 15px;
  font-size: 14px;
  border: 1px solid #f19400;

  option {
    margin: 15px;
    background: #fff;
    color: #1119c1;
  }
`;

export const Table = styled.div`
  height: 100%;
  overflow: auto;
  max-height: 499px;
  overflow-x: auto;
`;

export const DeleteButton = styled.button`
  background: none;
  border: none;
`;

// import styled from 'styled-components';
// import { Select } from '@rocketseat/unform';

// export const Container = styled.div`
//   @import url('https://fonts.googleapis.com/css?family=Roboto&display=swap');
//   display: grid;
//   width: 70%;
//   height: 90%;
//   background: #ffff;
//   grid-template-rows: 30% 70%;
//   grid-template-columns: 1fr;
//   margin: auto;

//   header {
//     display: flex;
//     flex-direction: column;
//     grid-row-start: 1/2;
//     color: #666;
//     margin: 5px 0;
//     padding: 10px 0;

//     strong {
//       font-size: 20px;
//       align-self: center;
//       font-family: 'Roboto' Arial, Helvetica, sans-serif;
//     }
//   }

//   main {
//     display: flex;
//     background: #fff;
//   }
// `;
