import styled from 'styled-components';
import backImage from '../../assets/footer_image.jpg';

export const Container = styled.div`
  height: 120px;
  background: url(${backImage}) no-repeat center;
  background-size: cover;
  position: relative;
  bottom: 0;
  width: 100%;
  display: flex;
  border-bottom-left-radius: 5px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 20px;

  p {
    font-size: 10px;
    font-weight: bold;
    color: #e7e7e7;
    align-items: center;
  }
`;

export const Title = styled.h3`
  font-size: 20px;
  color: #f19400;
  margin-left: 15px;
`;

export const CompLabel = styled.h4`
  font-size: 12px;
  color: #e7e7e7;
  margin-left: 15px;
  margin-top: 6px;
`;
