/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-console */
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import {
  TextField,
  Button,
  Box,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';

import { ThemeProvider } from '@material-ui/core/styles';
import CompanyComboBox from '../../components/CompanyComboBox';
import DepartmentComboBox from '../../components/DepartmentComboBox';
import api from '../../services/api';

import ErrorText from '../../components/ErrorText';
import Footer from '../../components/Footer';

import {
  Container,
  CardContainer,
  Background,
  InputContainer,
  CheckBoxContainer,
  ImgContainer,
  theme,
} from './styles';

const validationSchema = Yup.object({
  company_id: Yup.number().required('Escolha a empresa'),
  name: Yup.string().required('Nome é obrigatório'),
  cpf: Yup.string()
    .min(4, 'Deve conter 4 caracteres')
    .max(11, 'Deve conter 11 caracteres')
    .required('CPF é obrigatório!!'),
  office: Yup.string().required('Função é obrigatória!'),
  department_id: Yup.number().required('Setor é obrigatório!'),
  email: Yup.string()
    .email('Precisa ser um email válido')
    .nullable(),
  password: Yup.string()
    .nullable()
    .min(6, 'No mínimo 6 caracteres!'),

  is_active: Yup.boolean().required('Usuário precisa estar ativo!'),
  is_admin: Yup.boolean().nullable(),
});

function CustomerRegister() {
  const [company, setCompany] = useState(null);
  const history = useHistory();
  const profile = useSelector(state => state.user.profile);

  const onSubmit = async (values, formikHelpers) => {
    try {
      await api.post('/users', values);
      formikHelpers.resetForm();
      toast.success(`Usuário ${values.name} cadastrado com sucesso!`);
    } catch (err) {
      toast.error('Não foi possivel cadastrar');
      formikHelpers.resetForm();
    }
  };

  const formName = 'Cadastro de Usuários';

  const onCancel = () => {
    history.push('/home');
  };

  const initialValues = {
    company_id: '',
    name: '',
    cpf: '',
    department_id: '',
    office: '',
    email: '',
    password: '',
    is_active: true,
    is_admin: false,
  };

  const handleCompany = comp => {
    setCompany(comp);
  };

  return (
    <Formik
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      initialValues={initialValues}
    >
      {({ isSubmitting, setFieldValue }) => (
        <Container>
          <CardContainer>
            <Form autoComplete="off">
              <ThemeProvider theme={theme}>
                <InputContainer>
                  <CompanyComboBox
                    id="company_id"
                    name="company_id"
                    label="Empresa"
                    changeCompany={(e, value) => {
                      setFieldValue('company_id', value.id);
                      handleCompany(value.id);
                    }}
                  />

                  <ErrorMessage name="company_id" component={ErrorText} />
                </InputContainer>
                <InputContainer>
                  <Field
                    id="name"
                    label="Nome"
                    variant="outlined"
                    name="name"
                    type="text"
                    autoComplete="off"
                    as={TextField}
                  />
                  <ErrorMessage name="name" component={ErrorText} />
                </InputContainer>
                <InputContainer>
                  <Field
                    type="text"
                    id="cpf"
                    label="CPF"
                    variant="outlined"
                    name="cpf"
                    as={TextField}
                  />
                  <ErrorMessage name="code" component={ErrorText} />
                </InputContainer>

                <InputContainer>
                  <Field
                    id="office"
                    label="Função"
                    variant="outlined"
                    name="office"
                    type="text"
                    as={TextField}
                  />
                  <ErrorMessage name="office" component={ErrorText} />
                </InputContainer>

                <InputContainer>
                  <DepartmentComboBox
                    id="department_id"
                    name="department_id"
                    label="Departamento"
                    comboCompany={company}
                    changeDepartment={(e, value) => {
                      setFieldValue('department_id', value.id);
                    }}
                  />

                  <ErrorMessage name="department_id" component={ErrorText} />
                </InputContainer>

                {profile.company_id === 1 ? (
                  <>
                    <InputContainer>
                      <Field
                        id="email"
                        label="Email"
                        variant="outlined"
                        name="email"
                        type="text"
                        autoComplete="off"
                        as={TextField}
                      />
                      <ErrorMessage name="email" component={ErrorText} />
                    </InputContainer>

                    <InputContainer>
                      <Field
                        id="password"
                        label="Senha"
                        variant="outlined"
                        name="password"
                        type="password"
                        autoComplete="off"
                        as={TextField}
                      />
                      <ErrorMessage name="password" component={ErrorText} />
                    </InputContainer>

                    <CheckBoxContainer>
                      <FormControlLabel
                        control={<Checkbox id="is_active" name="is_active" />}
                        label="Ativo"
                        checked
                        disabled
                      />
                      <ErrorMessage name="is_active" component={ErrorText} />
                      <FormControlLabel
                        control={<Checkbox id="is_admin" name="is_admin" />}
                        label="Admin"
                        disabled
                      />
                      <ErrorMessage name="is_admin" component={ErrorText} />
                    </CheckBoxContainer>
                  </>
                ) : null}
                <Box marginTop={1}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting}
                    fullWidth
                  >
                    Gravar
                  </Button>
                </Box>
                <Box marginTop={1}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="secondary"
                    fullWidth
                    onClick={onCancel}
                  >
                    Cancelar
                  </Button>
                </Box>
              </ThemeProvider>
            </Form>
            <ImgContainer>
              <Footer title={formName} comp={profile.company} />
            </ImgContainer>
          </CardContainer>
          <Background />
        </Container>
      )}
    </Formik>
  );
}

export default CustomerRegister;
