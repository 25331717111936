import React from 'react';
import { Link } from 'react-router-dom';

import { Container, CompanyCard, UserCard, TerminalCard } from './styles';
import CompanyLogo from '../../assets/cadastro.svg';
import UserLogo from '../../assets/relatorio.svg';

export default function Register() {
  return (
    <Container>
      <CompanyCard>
        <div>
          <img src={CompanyLogo} alt="company_logo" />
          <strong>Empresas</strong>
        </div>
        <Link to="/compregister">Adicionar</Link>
      </CompanyCard>

      <UserCard>
        <div>
          <img src={UserLogo} alt="user_logo" />
          <strong>Usuários</strong>
        </div>
        <Link to="/userregister">Adicionar </Link>
      </UserCard>

      <TerminalCard>
        <div>
          <img src={UserLogo} alt="user_logo" />
          <strong>Terminais</strong>
        </div>
        <Link to="/userregister">Adicionar </Link>
      </TerminalCard>
    </Container>
  );
}
