import styled from 'styled-components';
import { darken } from 'polished';

export const Wrapper = styled.div`
  height: 100%;
  background: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Content = styled.div`
  width: 100%;
  max-width: 315px;
  text-align: center;

  form {
    display: flex;
    flex-direction: column;
  }

  input {
    background: #fff;
    border: 1px solid;
    border-color: #f5a118;
    border-radius: 5px;
    height: 44px;
    padding: 0 15px;
    margin: 0 0 10px;
  }

  span {
    color: #111;
    font-weight: bold;
    margin: 0 0 10px;
  }

  button {
    margin: 5px 0 0;
    height: 44px;
    background: #f5a118;
    border: 1px solid;
    border-color: #111;
    border-radius: 5px;
    font-weight: bold;
    font-size: 16px;
    transition: background 0.2s;

    &:hover {
      background: ${darken(0.03, '#f5a118')};
    }
  }

  a {
    color: #333;
    margin-top: 15px;
    font-size: 16px;
    opacity: 0.8;

    &:hover {
      opacity: 1;
    }
  }
`;
