import React from 'react';
import { Link } from 'react-router-dom';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import AddIcon from '@material-ui/icons/Add';

export default function ValidationToolBar() {
  return (
    <>
      <Tooltip title="Criar Usuário">
        <Link to="/userregister">
          <IconButton>
            <AddIcon />
          </IconButton>
        </Link>
      </Tooltip>
    </>
  );
}
