/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Autocomplete } from '@material-ui/lab';
import { TextField, CircularProgress } from '@material-ui/core';
import api from '../../services/api';

export default function CompanyComboBox(props) {
  const [open, setOpen] = useState(false);
  const [options, setOptions] = React.useState([]);
  const profile = useSelector(state => state.user.profile);
  const loading = open && options.length === 0;
  const { id, name, label, changeCompany } = props;

  useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    (async () => {
      if (profile.company_id === 1) {
        const response = await api.get('companies');
        const companyOptions = await response.data;

        if (active) {
          setOptions(
            companyOptions.map(c => ({
              id: c.id,
              razao: c.razao,
            }))
          );
        }
      } else {
        const response = await api.get('companies', {
          params: {
            id: profile.company_id,
          },
        });

        const companyOptions = await response.data;

        if (active) {
          setOptions(
            companyOptions.map(c => ({
              id: c.id,
              razao: c.razao,
            }))
          );
        }
      }
    })();

    return () => {
      active = false;
    };
  }, [loading, profile.company_id]);

  React.useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  return (
    <Autocomplete
      id={id}
      name={name}
      style={{ width: 350 }}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      getOptionLabel={option => option.razao}
      getOptionSelected={(option, value) => option.razao === value.razao}
      options={options}
      loading={loading}
      onChange={changeCompany}
      disableClearable
      renderInput={params => (
        <TextField
          {...params}
          label={label}
          variant="outlined"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
}

CompanyComboBox.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  changeCompany: PropTypes.func.isRequired,
};
