import React from 'react';
// import { Form, Input } from '@rocketseat/unform';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { Box, Button, TextField } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core/styles';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';

import { useSelector } from 'react-redux';
import api from '../../services/api';
// import { updateProfileRequest } from '../../store/modules/user/actions';

import {
  Container,
  CardContainer,
  InputContainer,
  ImgContainer,
  theme,
} from './styles';
import Footer from '../../components/Footer';
import ErrorText from '../../components/ErrorText';

export default function UserProfile() {
  // const dispatch = useDispatch();
  const history = useHistory();
  const profile = useSelector(state => state.user.profile);

  const onSubmit = async (values, formikHelpers) => {
    const { name, email, old_password, password } = values;

    const dataValues = {
      name,
      email,
      ...(old_password
        ? {
            old_password,
            password,
          }
        : {}),
    };
    try {
      await api.put(`/profile/${profile.id}`, {
        name: dataValues.name,
        email: dataValues.email,
        old_password: dataValues.old_password,
        password: dataValues.password,
      });
      formikHelpers.resetForm();
      toast.success(`Usuário ${values.name} cadastrado com sucesso!`);
    } catch (err) {
      toast.error('Não foi possivel cadastrar');
      formikHelpers.resetForm();
    }
  };

  const onCancel = () => {
    history.push('/home');
  };

  const validationSchema = Yup.object({
    name: Yup.string().required('Nome é obrigatório'),
    email: Yup.string()
      .email('Precisa ser um email válido')
      .nullable(),
    old_password: Yup.string(),
    password: Yup.string().min(6, 'No mínimo 6 caracteres!'),
    password_confirmation: Yup.string()
      .oneOf([Yup.ref('password')], 'Confirmação Incorreta')
      .required('Confirmação obrigatoria'),
  });

  const initialValues = prof => {
    return {
      name: prof.name,
      email: prof.email,
      old_password: '',
      password: '',
      password_confirmation: '',
    };
  };

  const formName = 'Atualização de Perfil';

  return (
    <>
      <Container>
        <Formik
          validationSchema={validationSchema}
          onSubmit={onSubmit}
          initialValues={initialValues(profile)}
        >
          {({ isSubmitting }) => (
            <Container>
              <CardContainer>
                <Form autoComplete="off">
                  <ThemeProvider theme={theme}>
                    <InputContainer>
                      <Field
                        id="name"
                        label="Nome"
                        variant="outlined"
                        name="name"
                        type="text"
                        autoComplete="off"
                        as={TextField}
                      />
                      <ErrorMessage name="name" component={ErrorText} />
                    </InputContainer>

                    <InputContainer>
                      <Field
                        id="email"
                        label="Email"
                        variant="outlined"
                        name="email"
                        type="text"
                        autoComplete="off"
                        as={TextField}
                      />
                      <ErrorMessage name="email" component={ErrorText} />
                    </InputContainer>

                    <Box marginTop={5}>
                      <InputContainer>
                        <Field
                          id="old_password"
                          label="Senha Antiga"
                          variant="outlined"
                          name="old_password"
                          type="password"
                          autoComplete="off"
                          as={TextField}
                        />
                        <ErrorMessage
                          name="old_password"
                          component={ErrorText}
                        />
                      </InputContainer>
                      <InputContainer>
                        <Field
                          id="password"
                          label="Nova Senha"
                          variant="outlined"
                          name="password"
                          type="password"
                          autoComplete="off"
                          as={TextField}
                        />
                        <ErrorMessage name="password" component={ErrorText} />
                      </InputContainer>
                      <InputContainer>
                        <Field
                          id="password_confirmation"
                          label="Confirmação de Senha"
                          variant="outlined"
                          name="password_confirmation"
                          type="password"
                          autoComplete="off"
                          as={TextField}
                        />
                        <ErrorMessage
                          name="password_confirmation"
                          component={ErrorText}
                        />
                      </InputContainer>
                    </Box>

                    <Box marginTop={3}>
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        disabled={isSubmitting}
                        fullWidth
                      >
                        Gravar
                      </Button>
                    </Box>
                    <Box marginTop={1}>
                      <Button
                        type="submit"
                        variant="contained"
                        color="secondary"
                        fullWidth
                        onClick={onCancel}
                      >
                        Cancelar
                      </Button>
                    </Box>
                  </ThemeProvider>
                </Form>
                <ImgContainer>
                  <Footer title={formName} comp={profile.company} />
                </ImgContainer>
              </CardContainer>
            </Container>
          )}
        </Formik>

        {/* <strong>PERFIL DO USUÀRIO</strong> */}
        {/* <AvatarInput name="file_id" /> */}
        {/* <Form initialData={profile} onSubmit={submitForm}>
          <Input name="name" type="text" placeholder="Nome completo" />
          <Input name="cpf" type="text" placeholder="CPF" />
          <Input name="office" type="text" placeholder="Função/Setor" />
          <Input name="email" type="email" placeholder="EMAIL" />
          <Input name="password" type="password" placeholder="Senha Antiga" />
          <Input name="newpassword" type="password" placeholder="Nova Senha" />
          <button type="submit">ATUALIZAR PERFIL</button>
        </Form> */}
      </Container>
    </>
  );
}
