/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-console */
import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import {
  TextField,
  Button,
  Box,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';

import { ThemeProvider } from '@material-ui/core/styles';
import api from '../../services/api';

import ErrorText from '../../components/ErrorText';
import Footer from '../../components/Footer';

import {
  Container,
  CardContainer,
  Background,
  InputContainer,
  CheckBoxContainer,
  ImgContainer,
  theme,
} from './styles';

const validationSchema = Yup.object({
  razao: Yup.string().required('Escolha a empresa'),
  bairro: Yup.string().required(' Bairro é obrigatório'),
  cidade: Yup.string().required('Cidade é obrigatório'),
  CNPJ: Yup.string()
    .min(14, 'Deve conter 11 caracteres')
    .max(14, 'Deve conter 11 caracteres')
    .required('CPF é obrigatório!!'),
  is_active: Yup.boolean().required('Usuário precisa estar ativo!'),
});

function CompRegister() {
  const history = useHistory();
  const profile = useSelector(state => state.user.profile);

  const onSubmit = async (values, formikHelpers) => {
    try {
      await api.post('/companies', values);
      formikHelpers.resetForm();
      toast.success(`Empresa ${values.razao} cadastrada com sucesso!`);
    } catch (err) {
      toast.error('Não foi possivel cadastrar');
      formikHelpers.resetForm();
    }
  };

  const formName = 'Cadastro de Usuários';

  const onCancel = () => {
    history.push('/home');
  };

  const initialValues = {
    razao: '',
    bairro: '',
    cidade: '',
    CNPJ: '',
    is_active: true,
  };

  return (
    <Formik
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      initialValues={initialValues}
    >
      {({ isSubmitting }) => (
        <Container>
          <CardContainer>
            <Form autoComplete="off">
              <ThemeProvider theme={theme}>
                <InputContainer>
                  <Field
                    type="text"
                    id="razao"
                    label="Razão"
                    variant="outlined"
                    name="razao"
                    autoComplete="off"
                    as={TextField}
                    style={{ width: '350px', marginX: '8px' }}
                  />
                  <ErrorMessage name="razao" component={ErrorText} />
                </InputContainer>
                <InputContainer>
                  <Field
                    type="text"
                    id="bairro"
                    label="Bairro"
                    variant="outlined"
                    name="bairro"
                    autoComplete="off"
                    as={TextField}
                    style={{ width: '350px', marginX: '8px' }}
                  />
                  <ErrorMessage name="bairro" component={ErrorText} />
                </InputContainer>

                <InputContainer>
                  <Field
                    type="text"
                    id="cidade"
                    label="Cidade"
                    variant="outlined"
                    name="cidade"
                    autoComplete="off"
                    as={TextField}
                    style={{ width: '350px', marginX: '8px' }}
                  />
                  <ErrorMessage name="cidade" component={ErrorText} />
                </InputContainer>

                <InputContainer>
                  <Field
                    type="text"
                    id="CNPJ"
                    label="CNPJ"
                    variant="outlined"
                    name="CNPJ"
                    autoComplete="off"
                    as={TextField}
                    style={{ width: '350px', marginX: '8px' }}
                  />
                  <ErrorMessage name="CNPJ" component={ErrorText} />
                </InputContainer>

                <CheckBoxContainer>
                  <FormControlLabel
                    control={<Checkbox id="is_active" name="is_active" />}
                    label="Ativo"
                  />
                  <ErrorMessage name="is_active" component={ErrorText} />
                </CheckBoxContainer>

                <Box marginTop={1}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting}
                    fullWidth
                  >
                    Gravar
                  </Button>
                </Box>
                <Box marginTop={1}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="secondary"
                    fullWidth
                    onClick={onCancel}
                  >
                    Cancelar
                  </Button>
                </Box>
              </ThemeProvider>
            </Form>
            <ImgContainer>
              <Footer title={formName} comp={profile.company} />
            </ImgContainer>
          </CardContainer>
          <Background />
        </Container>
      )}
    </Formik>
  );
}

export default CompRegister;
