/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { TextField, Button, Box } from '@material-ui/core';
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { orange } from '@material-ui/core/colors';
import {
  Container,
  CardContainer,
  InputContainer,
  ImgContainer,
  Background,
} from './styles';
import api from '../../services/api';
import ErrorText from '../../components/ErrorText';
import Footer from '../../components/Footer';

const validationSchema = Yup.object({
  name: Yup.string().required('Campo obrigatório!'),
});

const theme = createMuiTheme({
  palette: {
    primary: orange,
    secondary: {
      main: '#2c2e2c',
    },
  },
});

export default function DepartmentUpdate() {
  const [department, setDepartment] = useState();
  const history = useHistory();
  const { id } = useParams();
  const profile = useSelector(state => state.user.profile);

  const formName = 'Atualização de Departamento';

  useEffect(() => {
    async function getDepartment() {
      const response = await api.get(`departments/${id}`, {
        params: {
          company_id: profile.company_id,
        },
      });
      setDepartment(response.data.name);
    }
    getDepartment();
  }, [id, profile.company_id]);
  const handleCancel = () => {
    history.goBack();
  };

  const initialValues = {
    name: '',
  };

  const onSubmit = async (values, formikHelpers) => {
    try {
      await api.put(`/departments/${id}`, {
        name: values.name,
      });
      history.goBack();
      toast.success(`Usuário ${values.name} cadastrado com sucesso!`);
    } catch (err) {
      toast.error('Não foi possivel cadastrar');
      formikHelpers.resetForm();
    }
  };

  return (
    <Container>
      <CardContainer>
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
        >
          {({ isSubmitting }) => (
            <Form autoComplete="off">
              <ThemeProvider theme={theme}>
                <InputContainer>
                  <Box>
                    <Field
                      as={TextField}
                      name="name"
                      id="name"
                      label={department}
                      variant="outlined"
                      type="text"
                      style={{ width: '350px', marginX: '8px' }}
                      placeholder={department}
                    />
                    <ErrorMessage name="name" component={ErrorText} />
                  </Box>
                </InputContainer>
                <Box marginTop={2}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting}
                    fullWidth
                  >
                    Gravar
                  </Button>
                </Box>
                <Box marginTop={2}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="secondary"
                    fullWidth
                    onClick={handleCancel}
                  >
                    Cancelar
                  </Button>
                </Box>
              </ThemeProvider>
            </Form>
          )}
        </Formik>
        <ImgContainer>
          <Footer title={formName} comp={profile.company} />
        </ImgContainer>
      </CardContainer>
      <Background />
    </Container>
  );
}
