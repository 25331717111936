/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-console */
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import { TextField, Button, Box, FormControlLabel } from '@material-ui/core';

import { Switch } from 'formik-material-ui';
import { ThemeProvider } from '@material-ui/core/styles';
import CompanyComboBox from '../../components/CompanyComboBox';
import DepartmentComboBox from '../../components/DepartmentComboBox';
import api from '../../services/api';

import ErrorText from '../../components/ErrorText';
import Footer from '../../components/Footer';

import {
  Container,
  CardContainer,
  Background,
  InputContainer,
  CheckBoxContainer,
  ImgContainer,
  SideDataContainer,
  UserCardContainer,
  UserCompanyTitle,
  UserName,
  UserData,
  UserOffice,
  UserDepartment,
  theme,
} from './styles';

const validationSchema = Yup.object({
  company_id: Yup.number().required('Escolha a empresa'),
  name: Yup.string().required('Nome é obrigatório'),
  cpf: Yup.string()
    .min(4, 'Deve conter 4 caracteres')
    .max(11, 'Deve conter 11 caracteres')
    .required('CPF é obrigatório!!'),
  office: Yup.string().required('Função é obrigatória!'),
  department_id: Yup.number().required('Setor é obrigatório!'),
  is_active: Yup.boolean(),
});

function CustomerUpdate() {
  const [company, setCompany] = useState(null);
  const [user, setUser] = useState();
  const { id } = useParams();
  const history = useHistory();
  const profile = useSelector(state => state.user.profile);

  const onSubmit = async (values, formikHelpers) => {
    try {
      await api.put(`/users/${id}`, values);
      formikHelpers.resetForm();
      toast.success(`Usuário ${values.name} Atualizado com sucesso!`);
    } catch (err) {
      toast.error('Não foi possivel atualizar');
      formikHelpers.resetForm();
    }
  };

  useEffect(() => {
    async function loadUser() {
      const response = await api.get(`users/${id}`);
      setUser(response.data);
    }
    loadUser();
  }, [id]);

  const handleCompany = comp => {
    setCompany(comp);
  };

  const formName = 'Alteração de Usuários';

  const onCancel = () => {
    history.push('/home');
  };

  const initialValues = {
    company_id: '',
    name: '',
    cpf: '',
    department_id: '',
    office: '',
    is_active: user && user.is_active,
  };

  return (
    <Formik
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      initialValues={initialValues}
      enableReinitialize
    >
      {({ isSubmitting, setFieldValue }) => (
        <Container>
          <CardContainer>
            {user && (
              <Form autoComplete="off">
                <ThemeProvider theme={theme}>
                  <InputContainer>
                    <CompanyComboBox
                      id="company_id"
                      name="company_id"
                      label={user.company}
                      changeCompany={(e, value) => {
                        setFieldValue('company_id', value.id);
                        handleCompany(value.id);
                      }}
                    />

                    <ErrorMessage name="company_id" component={ErrorText} />
                  </InputContainer>
                  <InputContainer>
                    <Field
                      id="name"
                      label={user.name}
                      variant="outlined"
                      name="name"
                      type="text"
                      autoComplete="off"
                      as={TextField}
                    />
                    <ErrorMessage name="name" component={ErrorText} />
                  </InputContainer>
                  <InputContainer>
                    <Field
                      type="text"
                      id="cpf"
                      label={user.cpf}
                      variant="outlined"
                      name="cpf"
                      as={TextField}
                    />
                    <ErrorMessage name="code" component={ErrorText} />
                  </InputContainer>

                  <InputContainer>
                    <Field
                      id="office"
                      label={user.office}
                      variant="outlined"
                      name="office"
                      type="text"
                      as={TextField}
                    />
                    <ErrorMessage name="office" component={ErrorText} />
                  </InputContainer>

                  <InputContainer>
                    <DepartmentComboBox
                      id="department_id"
                      name="department_id"
                      label={user.department}
                      comboCompany={company}
                      changeDepartment={(e, value) => {
                        setFieldValue('department_id', value.id);
                      }}
                    />

                    <ErrorMessage name="department_id" component={ErrorText} />
                  </InputContainer>

                  <CheckBoxContainer>
                    <FormControlLabel
                      control={
                        <Field
                          component={Switch}
                          type="checkbox"
                          color="primary"
                          name="is_active"
                          id="is_active"
                        />
                      }
                      label="Ativo"
                    />
                    <ErrorMessage name="is_active" component={ErrorText} />
                  </CheckBoxContainer>

                  <Box marginTop={1}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      disabled={isSubmitting}
                      fullWidth
                    >
                      Gravar
                    </Button>
                  </Box>
                  <Box marginTop={1}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="secondary"
                      fullWidth
                      onClick={onCancel}
                    >
                      Cancelar
                    </Button>
                  </Box>
                </ThemeProvider>
              </Form>
            )}
            <ImgContainer>
              <Footer title={formName} comp={profile.company} />
            </ImgContainer>
          </CardContainer>
          <Background>
            <SideDataContainer />
            {user ? (
              <UserCardContainer>
                <UserName>{user.name}</UserName>
                <UserData>{user.cpf}</UserData>
                <UserOffice>{user.office}</UserOffice>
                <UserDepartment>{user.department}</UserDepartment>
                <UserCompanyTitle>{user.company}</UserCompanyTitle>
              </UserCardContainer>
            ) : null}
          </Background>
        </Container>
      )}
    </Formik>
  );
}

export default CustomerUpdate;
