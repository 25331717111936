import React from 'react';

import { toast } from 'react-toastify';
import { css } from 'glamor';

import * as Yup from 'yup';
import { Form } from '@rocketseat/unform';
import api from '../../services/api';
import back_image from '../../assets/logos.png';

import { Container, TextInput, FootView } from './styles';

const schema = Yup.object().shape({
  code: Yup.string().required('Matrícula é obrigatória!'),
});
export default function Terminal() {
  async function handleSubmit({ code }, { resetForm }) {
    try {
      const response = await api.post('validations', {
        cpf: code,
      });

      const validate = response.data;

      toast.success(`Refeição registrada para ${validate.name} !`, {
        autoClose: 3000,
      });
    } catch (error) {
      toast(`Atenção: ${error.response.data.message}`, {
        autoClose: '5000',
        className: css({
          fontSize: '14px !important',
          fontFamily: 'sans-serif',
          backgroundColor: '#F00 !important',
          color: '#fff !important',
          padding: '15px !important',
        }),
      });
    }
    resetForm();
  }

  return (
    <Container>
      <Form schema={schema} onSubmit={handleSubmit}>
        <TextInput
          name="code"
          type="text"
          placeholder="Digite o código"
          autoFocus
          autoComplete="off"
        />
      </Form>
      <FootView>
        <img src={back_image} alt="logo" max />
      </FootView>
    </Container>
  );
}
