import styled from 'styled-components';
// import { darken } from 'polished';
import { createMuiTheme } from '@material-ui/core/styles';
import { orange } from '@material-ui/core/colors';

// export const Container = styled.div`
/* @import url('https://fonts.googleapis.com/css?family=Bangers|Shadows+Into+Light|Ubuntu&display=swap');
  display: flex;
  flex-direction: column;
  max-width: 1000px;
  width: 700px;
  margin: 80px auto;
  height: 700px;
  background: #eee; */

/* strong {
    align-items: center;
    justify-content: center;
    display: flex;
    font-size: 30px;
    color: #111;
    margin: 60px;
  }

  form {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: top;

    input {
      display: flex;
      background: #fff;
      border: 1px solid;
      border-color: #f19400;
      border-radius: 5px;
      height: 50px;
      padding: 0 15px;
      margin: 10px;
      max-height: auto;
      width: 400px;
    }

    span {
      color: #111;
      font-weight: bold;
      margin: 0 0 10px;
    }

    button {
      display: flex;
      margin: 10px 7px;
      width: 400px;
      min-width: 50px;
      height: 50px;
      align-items: center;
      justify-content: center;
      background: #f19400;
      border: 1px solid;
      border-color: #111;
      border-radius: 5px;
      font-weight: bold;
      font-size: 16px;
      transition: background 0.2s;

      &:hover {
        background: ${darken(0.03, '#f8843c')};
      }
    }
  }
 */
// `;

export const Container = styled.div`
  height: 91vh;
  display: flex;
  align-items: stretch;
  justify-content: center;
  background: #fdfee8;
`;

export const Title = styled.h3`
  font-family: 'Roboto';
  font-size: 26px;
  color: #333;
`;

export const CardContainer = styled.div`
  justify-content: space-between;
  background: #fff;
  flex-direction: column;
  display: flex;
  margin: 25px;
  border: 4px solid orange;
  border-radius: 8px;
  align-items: center;
  padding-top: 30px;

  margin-top: 20px;
  -webkit-box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.75);
`;

export const InputContainer = styled.div`
  flex-direction: column;
  display: flex;
  margin-top: 10px;
  margin-bottom: 10px;
`;

export const ImgContainer = styled.div`
  display: flex;
  width: 550px;
`;

export const CheckBoxContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const theme = createMuiTheme({
  palette: {
    primary: orange,
    secondary: {
      main: '#2c2e2c',
    },
  },
  overrides: {
    MuiInputLabel: {
      root: {
        fontSize: '16px',
        fontFamily: 'Roboto',
      },
    },
    MuiInputBase: {
      root: {
        height: '60px',
        width: '350px',
      },
    },
  },
});
