import { takeLatest, call, put, all } from 'redux-saga/effects';

import { toast } from 'react-toastify';

import api from '../../../services/api';
import history from '../../../services/history';

import { companyRegSuccess, companyRegFailure } from './actions';

export function* companyRegister({ payload }) {
  try {
    const { razao, bairro, cidade, is_active } = payload;

    const response = yield call(api.post, 'companies', {
      razao,
      bairro,
      cidade,
      is_active,
    });

    const company = response.data;

    toast.success('Empresa cadastrada com sucesso!');

    yield put(companyRegSuccess(company));

    history.push('/register');
  } catch (err) {
    toast.error('Falha no Cadastro da empresa, verifique os dados!');

    yield put(companyRegFailure());
  }
}

export default all([takeLatest('@company/COMP_REG_REQUEST', companyRegister)]);
