import styled from 'styled-components';
import { Input } from '@rocketseat/unform';
import { darken } from 'polished';

export const Container = styled.div`
  @import url('https://fonts.googleapis.com/css?family=Baloo+Bhai&display=swap');
  display: flex;
  flex-direction: column;
  background: black;
  height: 100%;
  width: 100%;
  margin: 0px auto;
  border: 4px solid #f5a118;
  border-radius: 0 0 55px 55px;
  position: absolute;
  padding-top: 30px;

  form {
    display: flex;
    flex-direction: column;
    height: 100%;
    border-radius: 0 0 55px 55px;
  }
`;

export const DisplayContent = styled(Input)`
  display: flex;
  border: 2px solid #f5a118;
  overflow: hidden;
  height: 20%;
  width: 90%;
  background: #fff;
  margin: 15px auto;
  align-items: center;
  font-size: 50px;
  font-family: 'Baloo Bhai', Arial, Helvetica, sans-serif;
  border: 5px solid #f5a118;
  border-radius: 5px;
`;

export const DisplayNumbers = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr 1fr;
  grid-template-areas:
    'digt digt digt'
    'digt digt digt'
    'digt digt digt'
    'digt';

  grid-auto-columns: 1fr;
  align-items: center;
  justify-content: center;
  padding: 10px 50px;
  margin-bottom: 20px;

  button {
    display: flex;
    font-size: 30px;
    font-weight: bold;
    border: 2px solid #eee;
    cursor: pointer;
    margin: 58px auto;
    width: 65%;
    height: 65%;
    border-radius: 10px;
    align-items: center;
    justify-content: center;
    background: #fd1;
  }
`;
export const FooterButtons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  height: 10%;
  width: 25%;
  align-self: flex-end;
  margin-right: 90px;

  button {
    flex: 1;
    font-size: 20px;
    border: 2px solid #f5a118;
    cursor: pointer;
    margin: 0 0 10px 0;
    position: relative;
    width: 25%;
    height: 100%;
    border-radius: 5px;
    font-weight: bold;
    background: #f5a118;
    transition: background 0.2s;

    &:hover {
      background: ${darken(0.09, '#fd1')};
    }
  }
`;
