import styled from 'styled-components';
import { createMuiTheme } from '@material-ui/core/styles';
import { orange } from '@material-ui/core/colors';
import homeImage from '../../assets/middle_background_sabor.jpg';

export const Container = styled.div`
  height: 91vh;
  display: flex;
  align-items: stretch;
  background: #fdfee8;
`;

export const CardContainer = styled.div`
  justify-content: space-between;
  background: #fff;
  flex-direction: column;
  display: flex;
  margin: 0 20px;
  border: 4px solid orange;
  border-radius: 8px;
  align-items: center;
  padding-top: 5px;

  margin-top: 5px;
  -webkit-box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.75);
`;

export const MainContent = styled.div`
  /* margin-top: ${props => (props.userData === 1 ? `15px` : `95px`)}; */
  margin-top: 15px;
`;
export const InputContainer = styled.div`
  flex-direction: column;
  display: flex;
  margin-top: 3px;
`;
export const MenuItem = styled.div`
  display: flex;
`;
export const ButtonContainer = styled.div`
  display: flex;
  margin: 20px 0;
  align-items: center;
  justify-content: space-between;
  button {
    width: 160px;
  }
`;
export const CheckBoxContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const Background = styled.div`
  flex: 1;
  background: url(${homeImage}) no-repeat center;
  background-size: cover;
`;

export const ImgContainer = styled.div`
  display: flex;
  width: 450px;
`;

export const SideDataContainer = styled.div`
  display: flex;
  align-items: flex-start;
  width: 150;
  height: 100;
  background: rgba(255, 255, 255, 0.5);
  margin: 0 auto;

  p {
    width: 150px;
  }
`;

export const UserCardContainer = styled.div`
  display: flex;
  background: rgba(255, 255, 255, 0.9);
  flex-direction: column;
  border-radius: 15px;
  border: 2px solid #f19400;

  margin-top: 15px;
  margin-left: 25px;
  justify-content: space-between;
  height: 240px;
  width: 210px;
  padding-top: 15px;
  -webkit-box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.75);
`;

export const UserCompanyTitle = styled.h3`
  font-family: 'Roboto';
  background: #3a3a3c;
  color: #f19400;
  width: 100%;
  font-size: 22px;
  text-align: center;
  height: 20%;
  padding-top: 12px;
`;
export const UserName = styled.strong`
  font-family: 'Roboto';
  font-size: 18px;
  text-align: center;
`;
export const UserData = styled.strong`
  font-family: 'Roboto';
  font-size: 18px;
  text-align: center;
`;
export const UserOffice = styled.strong`
  font-family: 'Roboto';
  font-size: 18px;
  text-align: center;
`;
export const UserDepartment = styled.strong`
  font-family: 'Roboto';
  font-size: 18px;
  text-align: center;
`;

export const theme = createMuiTheme({
  palette: {
    primary: orange,
    secondary: {
      main: '#2c2e2c',
    },
  },
  overrides: {
    MuiInputLabel: {
      root: {
        fontSize: '12px',
        fontFamily: 'Roboto',
      },
    },
    MuiInputBase: {
      root: {
        height: '40px',
      },
    },
  },
});
