import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  position: absolute;
  background-color: #191919;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;
export const InformContainer = styled.div`
  display: flex;
  width: 380px;
  height: 360px;
  flex-direction: column;
  background: #e2e2e2;
  align-items: center;
  justify-content: flex-start;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
`;
export const Logo = styled.div`
  display: flex;
  height: 140px;
  background-color: none;
`;
export const CheckContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 25px;
`;

export const Title = styled.strong`
  font-family: 'Poppins';
  font-weight: 500;
  font-size: 20px;
  margin-bottom: 5px;
  color: #a36629;
`;

export const CheckLogo = styled.div`
  display: flex;
  height: 40px;
  width: 40px;
  margin-right: 10px;

  img {
    height: 40px;
    width: 40px;
  }
`;
