import React from 'react';
import { Link } from 'react-router-dom';
import { Form, Input } from '@rocketseat/unform';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import logo from '../../assets/LogoSabor2.png';

import { signInRequest } from '../../store/modules/auth/actions';
import { Container } from './styles';

const schema = Yup.object().shape({
  email: Yup.string()
    .email('Email inválido!')
    .required('Email é obrigatório!'),
  password: Yup.string().required('A senha é Obrigatória!'),
});

export default function SignIn() {
  const dispatch = useDispatch();
  const loading = useSelector(state => state.auth.loading);

  function handleSubmit({ email, password }) {
    dispatch(signInRequest(email, password));
  }

  return (
    <Container>
      <img src={logo} alt="RefValidator" />

      <Form schema={schema} onSubmit={handleSubmit}>
        <Input name="email" type="email" placeholder="Seu email" />
        <Input name="password" type="password" placeholder="Sua senha" />

        <button type="submit">{loading ? 'Carregando...' : 'Entrar'}</button>
        <Link to="/forgotpass">Esqueceu a senha?</Link>
      </Form>
    </Container>
  );
}
