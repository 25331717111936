import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import api from '../../services/api';

export default function SelectCompany({ changeCompany }) {
  const [company, setCompany] = useState([]);

  useEffect(() => {
    (async () => {
      const response = await api.get('companies');

      const companies = response.data.map(c => ({
        value: c.id,
        label: `${c.id} - ${c.razao} - ${c.cidade}`,
      }));

      setCompany(companies);
    })().catch(err => {
      toast.error(`Falha na seleção: erro: ${err}`);
    });
  }, []);

  const customStyles = {
    input: styles => {
      return {
        ...styles,
        height: '30px',
        minHeight: '30px',
      };
    },
  };

  return (
    <div
      style={{
        width: '280px',
        marginRight: '15px',
        zIndex: 999,
      }}
    >
      <Select
        styles={customStyles}
        maxMenuHeight={400}
        placeholder="Escolha a Empresa"
        onChange={changeCompany}
        options={company}
      />
    </div>
  );
}

SelectCompany.propTypes = {
  changeCompany: PropTypes.func.isRequired,
};
