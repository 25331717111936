import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import MUIDataTable from 'mui-datatables';
import { Link } from 'react-router-dom';
import { MdEdit, MdDelete } from 'react-icons/md';
import CustomToolbar from './CustomToolBar';
import api from '../../services/api';

import SelectCompany from '../../components/SelectCompany';
import SelectCustomer from '../../components/SelectCustomer';
import { Container, Table, DeleteButton } from './styles';

export default function UserReports() {
  const profile = useSelector(state => state.user.profile);
  const [company, setCompany] = useState();
  const [data, setData] = useState();

  async function loadData() {
    const response = await api.get('departments', {
      params: {
        company_id: company,
      },
    });

    setData(response.data);
  }

  async function loadProfileData() {
    const response = await api.get('departments', {
      params: {
        company_id: profile.company_id,
      },
    });

    setData(response.data);
  }

  function handleProfile() {
    loadProfileData();
  }

  function handleLoad() {
    loadData();
  }

  // function handleDelete(id) {
  //   api.delete(`departments/${id}`);
  //   toast.info('Departamento Excluído');
  //   loadData();
  // }

  const columns = [
    {
      name: 'id',
      label: 'Cod',
      options: {
        filter: false,
      },
    },
    {
      name: 'name',
      label: 'Departamento',
      options: {
        filter: false,
      },
    },

    // {
    //   name: 'office',
    //   label: 'Função',
    //   options: {
    //     filter: true,
    //     filterType: 'checkbox',
    //     customBodyRender: value => {
    //       return value ? 'Porter' : 'Kiper';
    //     },
    //   },
    // },

    {
      name: 'id',
      label: 'Editar',
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRender: value => {
          return (
            <Link to={`/departments/${value}`}>
              <MdEdit size={25} color="#666" />
            </Link>
          );
        },
      },
    },

    // {
    //   name: 'id',
    //   label: 'Excluir',
    //   options: {
    //     filter: false,
    //     sort: false,
    //     empty: true,
    //     customBodyRender: value => {
    //       return (
    //         <DeleteButton type="button" onClick={() => {}}>
    //           <MdDelete size={25} color="#666" />
    //         </DeleteButton>
    //       );
    //     },
    //   },
    // },
    {
      name: 'id',
      label: 'Editar',
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRender: () => {
          return (
            <DeleteButton type="button" onClick={() => {}}>
              <MdDelete size={25} color="#666" />
            </DeleteButton>
          );
        },
      },
    },
  ];

  const options = {
    filter: true,
    filterType: 'dropdown',
    responsive: 'scrollFullHeight',

    selectableRows: 'none',
    customToolbar: () => {
      return <CustomToolbar />;
    },
  };

  return (
    <Container>
      <form>
        {profile.company_id && profile.company_id === 1 ? (
          <SelectCompany changeCompany={e => setCompany(e.value)} />
        ) : (
          <SelectCustomer changeCompany={e => setCompany(e.value)} />
        )}
        {profile.company_id && profile.company_id === 1 ? (
          <button type="button" onClick={handleLoad}>
            GERAR
          </button>
        ) : (
          <button type="button" onClick={handleProfile}>
            GERAR
          </button>
        )}
      </form>

      <Table>
        <MUIDataTable
          title="Departamentos"
          data={data}
          columns={columns}
          options={options}
        />
      </Table>
    </Container>
  );
}
