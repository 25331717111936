import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import logoCompany from '../../assets/LogoSabor.png';

import { Navigation, Container, Dropmenu, Logo } from './styles';

export default function TopNav() {
  const perfil = useSelector(state => state.user.profile);

  const container = React.createRef();

  const [cad, setCad] = useState(false);
  const [rel, setRel] = useState(false);

  function handleCadClick() {
    setCad(!cad);
    setRel(false);
  }

  function handleRelClick() {
    setRel(!rel);
    setCad(false);
  }

  function handleRemoveClick() {
    setCad(false);
    setRel(false);
  }

  // eslint-disable-next-line
  function handleClickOutside(event) {
    if (container.current && !container.current.contains(event.target)) {
      setCad(false);
      setRel(false);
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
  }, [handleClickOutside]);

  return (
    <Navigation>
      <Logo perfil={perfil} src={logoCompany} alt="Sabor" />

      <Container ref={container} perfil={perfil}>
        <ul>
          <Link onClick={handleRemoveClick} to="/home">
            PAINEL
          </Link>
          {/* <Link onClick={handleRemoveClick} to="/register">
            CADASTRO
          </Link> */}
          <li>
            {/* eslint-disable-next-line */}
            <strong onClick={handleCadClick}>CADASTROS</strong>
            <Dropmenu rel={cad ? 1 : 0} perfil={perfil}>
              <ul>
                {perfil.company_id === 1 && (
                  <li>
                    <Link onClick={handleRemoveClick} to="/compregister">
                      Empresas
                    </Link>
                  </li>
                )}
                <li>
                  <Link onClick={handleRemoveClick} to="/userregister">
                    Usuários
                  </Link>
                </li>
                <li>
                  <Link onClick={handleRemoveClick} to="/departmentregister">
                    Departamentos
                  </Link>
                </li>
              </ul>
            </Dropmenu>
          </li>

          <li>
            {/* eslint-disable-next-line */}
            <strong onClick={handleRelClick}>CONSULTAS</strong>
            <Dropmenu rel={rel ? 1 : 0} perfil={perfil}>
              <ul>
                <li>
                  <Link onClick={handleRemoveClick} to="/userreports">
                    Usuários
                  </Link>
                </li>
                <li>
                  <Link onClick={handleRemoveClick} to="/dapartmentreports">
                    Departamentos
                  </Link>
                </li>
                <li>
                  <Link onClick={handleRemoveClick} to="/validationreports">
                    Validações
                  </Link>
                </li>
              </ul>
            </Dropmenu>
          </li>
        </ul>
      </Container>
    </Navigation>
  );
}
