import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Select from 'react-select';
import PropTypes from 'prop-types';
import api from '../../services/api';

export default function SelectUser({ changeUser }) {
  const [user, setUser] = useState([]);

  const profile = useSelector(state => state.user.profile);
  // const partnerId = profile.partner;

  useEffect(() => {
    async function loadEvento() {
      const response = await api.get('users', {
        params: {
          company_id: profile.company_id,
        },
      });

      const users = response.data.map(u => ({
        value: u.id,
        label: `${u.id} - ${u.name} - ${u.office}`,
      }));

      setUser(users);
    }
    loadEvento();
  }, [profile.company_id]);

  const customStyles = {
    input: styles => {
      return {
        ...styles,
        height: '30px',
        minHeight: '30px',
      };
    },
  };

  return (
    <div style={{ width: '280px', marginRight: '15px', zIndex: 999 }}>
      <Select
        styles={customStyles}
        maxMenuHeight={400}
        placeholder="ESCOLHA O USUÁRIO"
        onChange={changeUser}
        options={user}
      />
    </div>
  );
}

SelectUser.propTypes = {
  changeUser: PropTypes.func,
};
SelectUser.defaultProps = {
  changeUser: PropTypes.func,
};
