import React from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { Form } from '@rocketseat/unform';
import api from '../../services/api';
import back_image from '../../assets/logos.png';

import { Container, TextInput, FootView, TopDarkView } from './styles';

const schema = Yup.object().shape({
  code: Yup.string().required('Matrícula é obrigatória!'),
});

export default function Terminal() {
  const terminal = useSelector(state => state.user.profile.terminal_id);
  const history = useHistory();

  async function handleSubmit({ code }, { resetForm }) {
    try {
      const response = await api.get('users', { params: { cpf: code } });

      const userData = response.data;

      const { name, cpf, office, departments, companies } = userData[0];
      const department = departments.name;
      const company = companies.razao;

      history.push(
        `/confirm/${name}/${office}/${department}/${company}/${cpf}`
      );

      // toast.success(`Refeição registrada para ${validate.name} !`, {
      //   autoClose: 3000,
      // });
    } catch (error) {
      toast.error('Falha ao validar');
    }
    // setActive(false);
    resetForm();
    // setFocus();
  }

  // const inputLength = false;

  return (
    <Container>
      {terminal && terminal ? <TopDarkView /> : null}
      {/* <ReactToPrint trigger={() => {}} content={() => componentRef.current} />
      <Recipe ref={componentRef} /> */}
      <Form schema={schema} onSubmit={handleSubmit}>
        <TextInput
          name="code"
          type="text"
          // maxLength={11}
          placeholder="Digite a matrícula"
          autoFocus
          autoComplete="off"
          // disabled={active}
          // ref={inputText}
        />
      </Form>
      <FootView>
        <img src={back_image} alt="logo" max />
      </FootView>
    </Container>
  );
}
