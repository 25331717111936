export function updateProfileRequest(name, cpf, office, email, password) {
  return {
    type: '@user/UPDATE_PROFILE_REQUEST',
    payload: {
      name,
      cpf,
      office,
      email,
      password,
    },
  };
}

export function updateProfileSuccess(profile) {
  return {
    type: '@user/UPDATE_PROFILE_SUCCESS',
    payload: { profile },
  };
}

export function updateProfileFailure() {
  return {
    type: '@user/UPDATE_PROFILE_FAILURE',
  };
}
