import React, { useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { useHistory, useParams } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';

import Recipe from '../../components/Recipe';
import confirmPageLogo from '../../assets/logo_confirm_page.png';
import axios from '../../services/api';
import {
  Container,
  InformContainer,
  Logo,
  Title,
  UserContainer,
  UserName,
  UserTitle,
  OfficeContainer,
  OfficeTitle,
  UserOffice,
  DepartmentContainer,
  DepartmentTitle,
  UserDepartment,
  UserCompany,
  ButtonContainer,
  ValidationButton,
  ReturnButton,
} from './styles';

export default function ValidationConfirm() {
  const { username, office, department, company, cpf } = useParams();
  const [validation, setValidation] = useState();
  const [loading, setLoading] = useState(false);

  const history = useHistory();
  const printRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
  });

  async function print() {
    await handlePrint();
  }

  async function handleConfirmValidation() {
    try {
      setLoading(true);

      const response = await axios.post('validations', {
        cpf,
      });

      setValidation(response.data);
      print();
      history.push('/generated');
    } catch (error) {
      toast.error('Falha ao validar');
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      <Container>
        <InformContainer>
          <Logo>
            <img src={confirmPageLogo} alt="RecipeImg" />
          </Logo>
          <Title>GERAR REFEIÇÂO PARA</Title>

          <UserContainer>
            <UserTitle>Nome</UserTitle>
            <UserName>{username}</UserName>
          </UserContainer>

          <OfficeContainer>
            <OfficeTitle>Cargo:</OfficeTitle>
            <UserOffice>{office}</UserOffice>
          </OfficeContainer>

          <DepartmentContainer>
            <DepartmentTitle>Setor:</DepartmentTitle>
            <UserDepartment>{department}</UserDepartment>
          </DepartmentContainer>

          <UserCompany>Empresa: {company}</UserCompany>
        </InformContainer>

        <ButtonContainer>
          <ReturnButton onClick={history.goBack} type="button">
            Cancelar
          </ReturnButton>
          <ValidationButton
            loading={loading ? 1 : 0}
            autoFocus
            type="submit"
            onClick={handleConfirmValidation}
          >
            {loading ? 'Imprimindo...' : 'Confirmar'}
          </ValidationButton>
        </ButtonContainer>
      </Container>

      <Recipe ref={printRef} validation={validation} />
    </>
  );
}
