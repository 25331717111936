import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  position: absolute;
  background-color: #191919;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;
export const InformContainer = styled.div`
  display: flex;
  width: 380px;
  height: 360px;
  flex-direction: column;
  background: #e2e2e2;
  align-items: center;
  justify-content: flex-start;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
`;
export const Logo = styled.div`
  display: flex;
  height: 140px;
  background-color: none;
`;
export const Title = styled.strong`
  font-family: 'Poppins';
  font-weight: 500;
  font-size: 20px;
  margin-bottom: 10px;
  color: #a36629;
`;

export const UserContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const UserTitle = styled.strong`
  font-family: 'Montserrat';
  font-size: 17px;
  font-weight: 500;
  margin-bottom: 10px;
  margin-right: 10px;
`;
export const UserName = styled.strong`
  font-family: 'Poppins';
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
`;
export const OfficeContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const OfficeTitle = styled.strong`
  font-family: 'Montserrat';
  font-size: 17px;
  font-weight: 500;
  margin-bottom: 10px;
  margin-right: 10px;
`;
export const UserOffice = styled.strong`
  font-family: 'Poppins';
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
`;
export const DepartmentContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const DepartmentTitle = styled.strong`
  font-family: 'Montserrat';
  font-size: 17px;
  font-weight: 500;
  margin-bottom: 10px;
  margin-right: 10px;
`;
export const UserDepartment = styled.strong`
  font-family: 'Poppins';
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
`;

export const UserCompany = styled.strong`
  font-family: 'Montserrat';
  font-size: 20px;
  font-weight: bold;
  margin-top: 15px;
  color: #21351e;
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  background: #e2e2e2;
  width: 380px;
  height: 80px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
`;

export const ValidationButton = styled.button`
  height: 80%;
  width: 100px;
  background: green;
  border-radius: 8px;
  margin-bottom: 15px;
  color: #fff;

  display: ${props => (props.loading ? 'none' : 'block')};
`;
export const ReturnButton = styled.button`
  height: 80%;
  width: 100px;
  background: red;
  border-radius: 8px;
  margin-bottom: 15px;
  color: #fff;
`;
