import styled from 'styled-components';
import { Input } from '@rocketseat/unform';
import back_image from '../../assets/logos.png';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  background: #191919;
  height: 100%;
  padding-top: 50px;
`;

export const TopDarkView = styled.div`
  display: block;
  position: absolute;
  width: 100%;
  height: 60px;
  top: 0;
  background: #191919;
`;

export const Form = styled.div`
  display: flex;
  justify-self: center;
`;
export const TextInput = styled(Input)`
  display: flex;
  width: 100%;
  background: #eee;
  font-size: 50px;
  height: 100%;
  border: 5px solid #f19400;
  border-radius: 15px;
`;

export const FootView = styled.div`
  display: flex;
  flex-direction: row;
  background-image: ${back_image};
  height: 70%;
  width: 100%;
  align-self: flex-start;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;
