/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-console */
import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { TextField, Button, Box } from '@material-ui/core';
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { orange } from '@material-ui/core/colors';
import api from '../../services/api';
import ErrorText from '../../components/ErrorText';
import Footer from '../../components/Footer';
import CompanyComboBox from '../../components/CompanyComboBox';
import {
  Container,
  CardContainer,
  InputContainer,
  Background,
  ImgContainer,
} from './styles';

const validationSchema = Yup.object({
  company_id: Yup.number().required('Campo obrigatório!'),
  name: Yup.string().required('Campo obrigatório!'),
});

const theme = createMuiTheme({
  palette: {
    primary: orange,
    secondary: {
      main: '#2c2e2c',
    },
  },
  overrides: {
    MuiInputLabel: {
      root: {
        fontSize: '12px',
        fontFamily: 'Roboto',
      },
    },
    MuiInputBase: {
      root: {
        height: '40px',
      },
    },
  },
});

export default function DepartmentRegister() {
  const profile = useSelector(state => state.user.profile);
  const history = useHistory();

  const handleCancel = () => {
    history.push('/home');
  };

  const initialValues = {
    company_id: '',
    name: '',
  };
  const onSubmit = async (values, formikHelpers) => {
    try {
      await api.post('/departments', {
        company_id: values.company_id,
        name: values.name,
      });
      formikHelpers.resetForm();
      toast.success(`Departamento ${values.name} cadastrado com sucesso!`);
    } catch (err) {
      toast.error('Não foi possivel cadastrar');
      formikHelpers.resetForm();
    }
  };
  const formName = 'Cadastro de Departamentos';

  return (
    <Container>
      <CardContainer>
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
        >
          {({ isSubmitting, setFieldValue }) => (
            <Form autoComplete="off">
              <ThemeProvider theme={theme}>
                <InputContainer>
                  <CompanyComboBox
                    id="company_id"
                    name="company_id"
                    label="Empresa"
                    changeCompany={(e, value) => {
                      setFieldValue('company_id', value.id);
                    }}
                  />
                  <ErrorMessage name="company_id" component={ErrorText} />
                </InputContainer>
                <InputContainer>
                  <Field
                    as={TextField}
                    name="name"
                    id="name"
                    label="Nome do Departamento"
                    variant="outlined"
                    type="text"
                    style={{ width: '350px', marginX: '8px' }}
                  />
                  <ErrorMessage name="name" component={ErrorText} />
                </InputContainer>
                <Box marginTop={5}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting}
                    fullWidth
                  >
                    Gravar
                  </Button>
                </Box>

                <Box marginTop={1}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="secondary"
                    fullWidth
                    onClick={handleCancel}
                  >
                    Cancelar
                  </Button>
                </Box>
              </ThemeProvider>
            </Form>
          )}
        </Formik>
        <ImgContainer>
          <Footer title={formName} comp={profile.company} />
        </ImgContainer>
      </CardContainer>
      <Background />
    </Container>
  );
}
