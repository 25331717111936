export function companyRegRequest(razao, bairro, cidade, is_active) {
  return {
    type: '@company/COMP_REG_REQUEST',
    payload: {
      razao,
      bairro,
      cidade,
      is_active,
    },
  };
}

export function companyRegSuccess(company) {
  return {
    type: '@company/COMP_REG_SUCCESS',
    payload: { company },
  };
}

export function companyRegFailure() {
  return {
    type: '@company/COMP_REG_FAILURE',
  };
}
