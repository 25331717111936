import styled from 'styled-components';
import avatarImg1 from '../../assets/logos3.png';
import avatarImg2 from '../../assets/amecor_logo.png';
import avatarImg3 from '../../assets/logo_login.jpg';
import avatarImg4 from '../../assets/sao_mateus_logo.png';

export const ContainerImg1 = styled.div`
  height: 32px;
  width: 120px;
  margin-left: 15px;
  display: flex;
  align-items: center;
  background: url(${avatarImg1}) no-repeat center;
  background-size: cover;
  border-radius: 5px;
`;
export const ContainerImg2 = styled.div`
  height: 32px;
  width: 120px;
  margin-left: 15px;
  display: flex;
  align-items: center;
  background: url(${avatarImg2}) no-repeat center;
  background-size: cover;
  border-radius: 2px;
`;
export const ContainerImg3 = styled.div`
  height: 32px;
  width: 120px;
  margin-left: 15px;
  display: flex;
  align-items: center;
  background: url(${avatarImg3}) no-repeat center;
  background-size: cover;
  border-radius: 15px;
`;
export const ContainerImg4 = styled.div`
  height: 32px;
  width: 120px;
  margin-left: 15px;
  display: flex;
  align-items: center;
  background: url(${avatarImg4}) no-repeat center;
  background-size: cover;
  border-radius: 15px;
`;
