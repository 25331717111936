import React, { useState } from 'react';

import { Form } from '@rocketseat/unform';

import * as Yup from 'yup';
import api from '../../services/api';

import { toast } from 'react-toastify';

import {
  Container,
  DisplayContent,
  DisplayNumbers,
  FooterButtons,
} from './styles';

const schema = Yup.object().shape({
  cpf: Yup.string()
    .required('CPF é obrigatório!')
    .length(11),
});

export default function Display() {
  const [data, setData] = useState('');
  const inputButtons = [];
  [7, 8, 9, 4, 5, 6, 1, 2, 3, 0].forEach(item => {
    inputButtons.push(
      <button
        onClick={e => {
          setData(data + e.target.value);
        }}
        value={item}
        key={item}
      >
        {item}
      </button>
    );
  });

  async function submitForm({ cpf }) {
    try {
      const response = await api.post('validations', {
        cpf,
      });

      const validaref = response.data;

      toast.success(`Refeição registrada para: ${validaref.name} !`, {
        autoClose: 5000,
      });
    } catch (err) {
      toast.error('  --- Refeição não registrada!! ---');
    }

    setData(data.substr(0, data.length - 11));
  }

  return (
    <Container>
      <Form schema={schema} onSubmit={submitForm}>
        <DisplayContent name="cpf" type="text" value={data} />

        <DisplayNumbers>{inputButtons}</DisplayNumbers>
        <FooterButtons>
          <button onClick={() => setData(data.substr(0, data.length - 1))}>
            LIMPAR
          </button>
          {/* <button type="submit">VALIDAR</button> */}
        </FooterButtons>
      </Form>
    </Container>
  );
}
