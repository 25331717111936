import styled from 'styled-components';
import { lighten } from 'polished';

export const Container = styled.div`
  background: ${props =>
    props.perfil
      ? `linear-gradient(-90deg, #e7c9c1, #111)`
      : `linear-gradient(-90deg, #2c2e2c, #2c2e2c)`};

  padding: 0 30px;
  border-bottom: 1px solid
    ${props =>
      props.perfil
        ? `${lighten(0.2, '#e7c9c1')}`
        : `${lighten(0.2, '#f5a118')}`};
`;

export const Content = styled.div`
  height: 54px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;

  aside {
    display: flex;
    align-items: center;
  }
`;

export const Profile = styled.div`
  display: flex;
  margin-left: 20px;
  padding-left: 20px;
  border-left: 1px solid #eee;
  height: 25px;

  div {
    text-align: right;
    margin-right: 10px;

    strong {
      font-family: 'Montserrat';
      font-weight: 500;
      display: block;
      color: #f5a23d;
      font-size: 13px;
    }

    h4 {
      display: block;
      color: #fff;
      font-weight: 500;
      cursor: pointer;
    }

    a {
      font-family: 'Montserrat';
      font-weight: 500;
      display: block;
      margin-top: 2px;
      font-size: 12px;
      color: rgb(255, 255, 255, 0.7);
    }
  }

  img {
    margin-left: 10px;
    width: 32px;
    height: 32px;
    border-radius: 50%;
  }
`;
