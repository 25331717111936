import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import pt from 'date-fns/locale/pt';
import MUIDataTable from 'mui-datatables';
import { format, getHours, addHours, parseISO } from 'date-fns';
import DatePicker from 'react-datepicker';
import { toast } from 'react-toastify';
import api from '../../services/api';
import ValidationToolBar from './ValidationToolBar';
import SelectCompany from '../../components/SelectCompany';
import SelectCustomer from '../../components/SelectCustomer';
import SelectUser from '../../components/SelectUser';
import { Container, Table } from './styles';

export default function ValidationReports() {
  const profile = useSelector(state => state.user.profile);
  const [company, setCompany] = useState();
  const [user, setUser] = useState();
  const [date1, setDate1] = useState(new Date());
  const [date2, setDate2] = useState(new Date());
  const [data, setData] = useState();

  const typeFilter = timeRange => {
    if (timeRange >= 5 && timeRange <= 9) {
      return 'CAFÉ';
    }
    if (timeRange >= 10 && timeRange <= 14) {
      return 'ALMOÇO';
    }
    if (timeRange >= 15 && timeRange <= 17) {
      return 'LANCHE';
    }
    if (timeRange >= 18 && timeRange <= 23) {
      return 'JANTAR';
    }
    return 'CEIA';
  };

  async function loadData() {
    const response = await api.get('validations', {
      params: {
        company_id: company,
        user_id: user,
        data1: format(date1, "yyyy-MM-dd'T00:00:00"),
        data2: format(date2, "yyyy-MM-dd'T23:59:59"),
        page: 1,
      },
    });

    const inform = response.data.map(col => ({
      ...col,
      newDate: format(
        addHours(parseISO(col.created_at), 6),
        'dd-MM-yyyy HH:mm:ss'
      ),
      type: typeFilter(getHours(addHours(parseISO(col.created_at), 6))),
    }));

    if (inform === undefined || inform.length === 0) {
      toast.error('Não houveram refeições neste horário');
    }
    setData(inform);
  }

  function handleLoad() {
    loadData();
  }

  async function loadProfileData() {
    const response = await api.get('validations', {
      params: {
        company_id: profile.company_id,
        user_id: user,
        data1: format(date1, "yyyy-MM-dd'T00:00:00"),
        data2: format(date2, "yyyy-MM-dd'T23:59:59"),
        page: 1,
      },
    });

    const inform = response.data.map(col => ({
      ...col,
      newDate: format(
        addHours(parseISO(col.created_at), 6),
        'dd-MM-yyyy HH:mm:ss'
      ),
      type: typeFilter(getHours(addHours(parseISO(col.created_at), 6))),
    }));

    if (inform === undefined || inform.length === 0) {
      toast.error('Não houveram refeições neste horário');
    }
    setData(inform);
  }

  function handleProfile() {
    loadProfileData();
  }
  const columns = [
    {
      name: 'id',
      label: 'Cod',
      options: {
        filter: false,
      },
    },

    {
      name: 'users.name',
      label: 'Colaborador',
      options: {
        filter: true,
      },
    },
    {
      name: 'users.office',
      label: 'Função',
      options: {
        filter: true,
      },
    },
    {
      name: 'departments.name',
      label: 'Setor',
      options: {
        filter: true,
      },
    },
    {
      name: 'newDate',
      label: 'Data',
      options: {
        filter: true,
      },
    },
    {
      name: 'type',
      label: 'Tipo',
      options: {
        filter: true,
      },
    },

    // {
    //   name: 'office',
    //   label: 'Função',
    //   options: {
    //     filter: true,
    //     filterType: 'checkbox',
    //     customBodyRender: value => {
    //       return value ? 'Porter' : 'Kiper';
    //     },
    //   },
    // },

    // {
    //   name: 'id',
    //   label: 'Editar',
    //   options: {
    //     filter: false,
    //     sort: false,
    //     empty: true,
    //     customBodyRender: value => {
    //       return (
    //         <Link to={`/users/${value}`}>
    //           <MdEdit size={25} color="#666" />
    //         </Link>
    //       );
    //     },
    //   },
    // },
  ];

  const options = {
    filter: true,
    filterType: 'dropdown',
    responsive: 'scrollFullHeight',

    selectableRows: 'none',
    customToolbar: () => {
      return <ValidationToolBar />;
    },
  };

  return (
    <Container>
      <form>
        {profile.company_id && profile.company_id === 1 ? (
          <SelectCompany changeCompany={e => setCompany(e.value)} />
        ) : (
          <SelectCustomer changeCompany={e => setCompany(e.value)} />
        )}
        <SelectUser changeUser={e => setUser(e.value)} />

        <div style={{ width: '280px', marginRight: '15px', zIndex: 999 }}>
          <DatePicker
            value={date1}
            selected={date1}
            onChange={date => setDate1(date)}
            locale={pt}
            dateFormat="dd/MM/yyyy"
            timeFormat
          />
        </div>

        <div style={{ width: '280px', marginRight: '15px', zIndex: 999 }}>
          <DatePicker
            selected={date2}
            onChange={date => setDate2(date)}
            locale={pt}
            dateFormat="dd/MM/yyyy"
          />
        </div>

        {profile.company_id && profile.company_id === 1 ? (
          <button type="button" onClick={handleLoad}>
            GERAR
          </button>
        ) : (
          <button type="button" onClick={handleProfile}>
            GERAR
          </button>
        )}
      </form>

      <Table>
        <MUIDataTable
          title="Refeições"
          data={data}
          columns={columns}
          options={options}
          paginate
        />
      </Table>
    </Container>
  );
}
