import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FiLogOut, FiPrinter, FiFileText } from 'react-icons/fi';

import { signOut } from '../../store/modules/auth/actions';

import {
  Container,
  Background,
  ContentContainer,
  HomeTitle,
  ItemsContainer,
  KeyboardCard,
  LogoutCard,
  KeyboardCardTitle,
  LogoutCardTitle,
  CardContainer,
  TerminalV1Card,
  TerminalV1CardTitle,
} from './styles';

export default function Dash() {
  const dispatch = useDispatch();
  const profile = useSelector(state => state.user.profile);

  function handleSignOut() {
    dispatch(signOut());
  }

  return (
    <Container>
      <ContentContainer>
        <HomeTitle>Principal</HomeTitle>
        <ItemsContainer>
          <CardContainer>
            {profile.company_id === 1 ? (
              <>
                <TerminalV1Card to="/teclado">
                  <FiFileText size={15} color="#f5a23d" />
                  <TerminalV1CardTitle>TECLADO</TerminalV1CardTitle>
                </TerminalV1Card>

                <KeyboardCard to="/terminal">
                  <FiPrinter size={15} color="#f5a23d" />
                  <KeyboardCardTitle>IMPRESSORA</KeyboardCardTitle>
                </KeyboardCard>
              </>
            ) : null}
          </CardContainer>

          <LogoutCard type="button" onClick={handleSignOut}>
            <FiLogOut size={15} color="#f5a23d" />
            <LogoutCardTitle>SAIR</LogoutCardTitle>
          </LogoutCard>
        </ItemsContainer>
      </ContentContainer>
      <Background />
    </Container>
  );
}
