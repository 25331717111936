import React from 'react';
import { Switch } from 'react-router-dom';

import Route from './Route';
import SignIn from '../pages/SignIn';

import Home from '../pages/Home';
import Production from '../pages/Production';
import UserReports from '../pages/UserReports';
import ValidationReports from '../pages/ValidationReports';
import ForgotPass from '../pages/ForgotPass';
import UserRegister from '../pages/CustomerRegister';
import CompRegister from '../pages/CompRegister';
import DepartmentRegister from '../pages/DepartmentRegister';
import DepartmentReports from '../pages/DepartmentReports';
import DepartmentUpdate from '../pages/DepartmentUpdate';
import Register from '../pages/Register';
import UserUpdate from '../pages/CustomerUpdate';
import Profile from '../pages/Profile';
import Display from '../pages/Display';
import Terminal from '../pages/Terminal';
import TerminalV1 from '../pages/TerminalV1';
import ValidationConfirm from '../pages/ValidationConfirm';
import ValidationGenerated from '../pages/ValidationGenerated';
import Recipe from '../components/Recipe';

export default function Routes() {
  return (
    <Switch>
      <Route path="/" exact component={SignIn} />
      <Route path="/forgotpass" component={ForgotPass} />

      <Route path="/home" component={Home} isPrivate />
      <Route path="/production" component={Production} isPrivate />
      <Route path="/userreports" component={UserReports} isPrivate />
      <Route
        path="/dapartmentreports"
        component={DepartmentReports}
        isPrivate
      />
      <Route
        path="/validationreports"
        component={ValidationReports}
        isPrivate
      />
      <Route path="/userregister" component={UserRegister} isPrivate />
      <Route path="/compregister" component={CompRegister} isPrivate />
      <Route path="/register" component={Register} isPrivate />
      <Route path="/users/:id" component={UserUpdate} isPrivate />
      <Route path="/departments/:id" component={DepartmentUpdate} isPrivate />
      <Route path="/profile" component={Profile} isPrivate />
      <Route path="/display" component={Display} isPrivate />
      <Route path="/terminal" component={Terminal} isPrivate />
      <Route path="/teclado" component={TerminalV1} isPrivate />
      <Route path="/recipes" component={Recipe} isPrivate />
      <Route
        path="/departmentregister"
        component={DepartmentRegister}
        isPrivate
      />

      <Route
        path="/confirm/:username/:office/:department/:company/:cpf"
        component={ValidationConfirm}
        isPrivate
      />
      <Route path="/generated" component={ValidationGenerated} isPrivate />
    </Switch>
  );
}
