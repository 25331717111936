import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Select from 'react-select';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import api from '../../services/api';

export default function SelectCustomer({ changeCompany }) {
  const profile = useSelector(state => state.user.profile);
  const [company, setCompany] = useState([]);

  useEffect(() => {
    (async () => {
      const response = await api.get('companies', {
        params: {
          id: profile.company_id,
        },
      });

      const companies = response.data.map(c => ({
        value: c.id,
        label: `${c.id} - ${c.razao} - ${c.cidade}`,
      }));

      setCompany(companies);
    })().catch(err => {
      toast.error(`Falha na seleção: erro: ${err}`);
    });
  }, [profile.company_id]);

  const customStyles = {
    input: styles => {
      return {
        ...styles,
        height: '30px',
        minHeight: '30px',
      };
    },
  };

  return (
    <div
      style={{
        width: '280px',
        marginRight: '15px',
        zIndex: 999,
      }}
    >
      <Select
        styles={customStyles}
        maxMenuHeight={400}
        placeholder="Escolha a Empresa"
        onChange={changeCompany}
        options={company}
      />
    </div>
  );
}

SelectCustomer.propTypes = {
  changeCompany: PropTypes.func.isRequired,
};
