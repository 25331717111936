import React from 'react';

import { Container, Content, Title, CompLabel } from './styles';

export default function Footer(props) {
  const { title, comp } = props;
  return (
    <Container>
      <Content>
        <Title>{title}</Title>
        <CompLabel>{comp}</CompLabel>
      </Content>
    </Container>
  );
}
