import styled from 'styled-components';
import homeImage from '../../assets/middle_background_sabor.jpg';

export const Container = styled.div`
  height: 91vh;
  display: flex;
  align-items: stretch;
  background: #fdfee8;
`;

export const Background = styled.div`
  flex: 1;
  background: url(${homeImage}) no-repeat center;
  background-size: cover;
`;

export const CardContainer = styled.div`
  justify-content: space-between;
  background: #fff;
  flex-direction: column;
  display: flex;
  margin: 25px;
  border: 4px solid orange;
  border-radius: 8px;
  align-items: center;

  margin-top: 20px;
  -webkit-box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.75);
`;
export const DepartmentContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 15px;
`;

export const InputContainer = styled.div`
  flex-direction: column;
  display: flex;
  margin-top: 100px;
  margin-bottom: 60px;
  padding: 18px 0;
`;

export const ImgContainer = styled.div`
  display: flex;
  width: 550px;
`;
