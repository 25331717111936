import React from 'react';
import { useSelector } from 'react-redux';

import {
  ContainerImg1,
  ContainerImg2,
  ContainerImg3,
  ContainerImg4,
} from './styles';

export default function CompAvatar() {
  const profile = useSelector(state => state.user.profile);

  switch (profile.company_id) {
    case 2:
      return <ContainerImg2 />;
    case 3:
      return <ContainerImg3 />;
    case 4:
      return <ContainerImg4 />;
    default:
      return <ContainerImg1 />;
  }
}
