import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import TopNav from '../TopNav';
import CompAvatar from '../CompAvatar';

import { Container, Content, Profile } from './styles';

export default function Header() {
  const profile = useSelector(state => state.user.profile);

  return (
    <Container>
      <Content>
        <TopNav />

        <aside>
          <Profile>
            <div>
              <strong>{profile.name} </strong>
              <Link to="/profile">Meu perfil</Link>
            </div>
            <CompAvatar />
          </Profile>
        </aside>
      </Content>
    </Container>
  );
}
